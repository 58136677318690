import { set, get, has, omit } from "./lodash";

export default function jsonTransformer(tconfig = [], obj) {
  let newobj = JSON.parse(JSON.stringify(obj));
  let x;
  let y;
  tconfig.forEach((rule) => {
    const { t, i, o } = rule;
    if (i && has(obj, i)) {
      switch (t) {
        case "copy":
          set(newobj, o, get(obj, i));
          break;
        case "rel2id":
          set(newobj, o, get(obj, [i, "id"]));
          break;
        case "id2rel":
          set(newobj, o, get(newobj, o, { id: get(obj, i), name: "" }));
          set(newobj, [o, "id"], get(obj, i));
          break;
        case "rel2name":
          set(newobj, o, get(obj, [i, "name"]));
          break;
        case "rel2string":
          set(newobj, o, get(obj, i));
          break;
        case "relArr2idArr":
          set(
            newobj,
            o,
            get(obj, i, []).map((v) => v.id)
          );
          break;
        case "omit":
          newobj = omit(newobj, i);
          break;
        case "json2str":
          x = get(obj, i, {});
          set(newobj, o, JSON.stringify(x || {}));
          break;
        case "str2json":
          y = get(obj, i, "{}");
          set(newobj, o, JSON.parse(y || "{}"));
          break;
        case "bool2yesno":
          x = get(obj, i, {});
          set(newobj, o, x ? "Yes" : "No");
          break;
        case "yesno2bool":
          x = get(obj, i, {});
          set(newobj, o, x === "Yes");
          break;
        case "paidSkus":
          set(newobj, "event_json.paid_skus", get(obj, i));
          break;
        default:
          break;
      }
    }
  });
  return newobj;
}
